<template>
  <multiselect
    :options="collections"
    :searchable="true"
    :internal-search="false"
    :loading="isSearching"
    :options-limit="50"
    :reset-after="false"
    :show-labels="false"
    :multiple="true"
    v-model="selectedCollection"
    track-by="id"
    label="title"
    placeholder="Επιλέξτε collection..."
    select-label="Πατήστε enter για επιλογή"
    @search-change="searchCollections"
    @select="selectCollection"
    @remove="handleRemove"
  >
    <template v-slot:option="props" class="option">
      <Photo :photos="props.option.photos" class="option__image" />
      <div class="option__desc">
        <span class="option__title">
          <b>{{ props.option.title }}</b>
        </span>
        <br />
        <span class="option__title">
          <i> Brand: {{ props.option.brand.title }} </i>
        </span>
      </div>
    </template>
    <template v-slot:noResult>
      Δε βρέθηκαν αποτελέσματα. Προσπαθήστε ξανά!!
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import _debounce from 'lodash/debounce';
import request from '@/utils/request';
import Photo from '@/views/components/Photo';

export default {
  inject: ['$validator'],
  components: {
    Multiselect,
    Photo,
  },
  props: {
    collection: Array,
  },
  data() {
    return {
      collections: [],
      isSearching: false,
    };
  },
  computed: {
    selectedCollection: {
      get() {
        return this.collection;
      },
      set(value) {
        return value;
      },
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    searchCollections: _debounce(async function(searchQuery) {
      if (searchQuery.length) {
        this.isSearching = true;
        const { data } = await request.get('/collections-search', {
          params: {
            term: searchQuery,
          },
        });
        this.isSearching = false;

        this.collections = data.collections;
      }
    }, 300),
    selectCollection(selectedOption) {
      this.$emit('select-collection', selectedOption);
    },
    handleRemove(selectedOption) {
      this.$emit('remove-collection', selectedOption);
    },
  },
};
</script>

<style scoped lang="scss">
.multiselect {
  margin: 0 auto;

  .option__desc {
    margin-left: 10px;
  }
}
</style>
