<template>
  <LoadingContainer :is-loading="isLoadingCustomers && !isLoaded">
    <div class="card">
      <customersHeader
        :total="paginationParams.count"
        :selections="selections"
        :has-selections="hasSelections"
        title="Όλοι οι Πελάτες"
        @navigate="navigate"
        @print="print"
        @prepareExportXLS="prepareExportXLS"
        @prepareExportCSV="prepareExportCSV"
      />
      <div class="card-content">
        <Filters
          :options="options"
          :is-loading="isFiltering"
          :filters="paginationParams.filters"
          general-label="Αναζήτηση με χρήση email, ονοματεπώνυμου"
          @navigate="navigate"
        />

        <NewPagination
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />

        <div class="columns">
          <div class="column is-full">
            <LoadingContainer
              :is-loading="isFiltering && isLoaded"
              :is-opaque="true"
              class="table-wrapper"
            >
              <table
                v-if="items.length"
                class="table is-bordered is-striped is-marginless is-fullwidth"
              >
                <thead>
                  <tr>
                    <th class="hidden-print">
                      <checkbox
                        v-model="selectedAll"
                        name="selected"
                        @input="selectAll"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Ονοματεπώνυμο"
                        attribute="firstName"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Email"
                        attribute="email"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Φύλο"
                        attribute="gender"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Ημ/νια Γένησης"
                        attribute="birthday"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Παραγγελίες"
                        attribute="count"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Τζίρος (&euro;)"
                        attribute="sum_products"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Ενεργοί Πόντοι"
                        attribute="profiles.loyalty_points"
                        @sort="sort"
                      />
                    </th>
                    <th>
                      <sortable-header
                        :sort-options="paginationParams.sort"
                        label="Loyalty Tier"
                        attribute="profiles.loyalty_tier"
                        @sort="sort"
                      />
                    </th>
                    <th class="hidden-print">Ενέργειες</th>
                  </tr>
                </thead>
                <tbody>
                  <customer
                    v-for="(item, index) in items"
                    :item="{ ...item, profile: { id: 0, ...item.profile } }"
                    :index="paginationParams.indexStartsFrom + index"
                    :key="item.id"
                    :selections="selections"
                    @askToDeleteItem="askToDeleteItem"
                    @toggleSelection="toggleSelection"
                  />
                </tbody>
              </table>
              <h4 v-else class="title is-4 has-text-centered">
                Δε βρέθηκαν πελάτες
              </h4>
            </LoadingContainer>
          </div>
        </div>

        <NewPagination
          v-if="items.length"
          :items="items"
          :total="paginationParams.count"
          :current-page="paginationParams.page"
          :pages-count="paginationParams.pages"
          :items-per-page="paginationParams.size"
          :is-loading="isLoading"
          @navigate="navigate"
        />
      </div>
    </div>

    <confirmation
      :model-name="selectedItem.fullName"
      :is-open="isOpen"
      description="Είστε βέβαιος για τη διαγραφή αυτού του χρήστη;"
      @closeModal="closeModal"
    />
  </LoadingContainer>
</template>

<script>
import { mapActions } from 'vuex';
import CustomersList from '../../components/CustomersList';

export default {
  extends: CustomersList,
  metaInfo: {
    title: 'Όλοι οι Πελάτες',
  },
  data: () => ({
    options: [
      {
        label: 'Γενική Αναζήτηση',
        title: 'general',
        type: 'string',
        value: null,
      },
      {
        label: 'Φύλο',
        title: 'gender',
        type: 'options',
        options: 'genders',
        optionsValue: 'value',
        value: null,
      },
      {
        label: 'Με Profile',
        title: 'has_profile',
        type: 'boolean',
        value: null,
      },
      {
        label: 'Loyalty Tier',
        title: 'loyalty_tier',
        type: 'options',
        options: 'loyalty_tiers',
        optionsValue: 'value',
        value: null,
      },
    ],
  }),
  watch: {
    $route: {
      async handler(newVal) {
        try {
          if (newVal.query.page) {
            // Redirections & filtering
            this.isFiltering = true;
            await this.getAllCustomers(newVal.query);
            this.isFiltering = false;
          } else {
            // Initial loading without query params
            this.$router
              .replace({
                name: this.$route.name,
                query: this.filterParams({
                  page: 1,
                  size: 50,
                  filters: {},
                  sort: {},
                }),
              })
              .catch(err => console.log(err));
          }
        } catch (err) {
          this.isFiltering = false;
          this.$notify({
            type: 'error',
            title: 'Αποτυχία',
            text: 'To αίτημα απέτυχε',
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getAllCustomers: 'customers/list/getAllCustomers',
    }),
    async navigate(params = {}) {
      try {
        this.isFiltering = true;
        this.$router
          .push({
            name: this.$route.name,
            query: this.filterParams({ ...this.paginationParams, ...params }),
          })
          .catch(err => console.log(err));
        this.isFiltering = false;
      } catch (err) {
        this.isFiltering = false;
        this.$notify({
          type: 'error',
          title: 'Αποτυχία',
          text: 'To αίτημα απέτυχε',
        });
      }
    },
  },
};
</script>
